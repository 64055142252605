import ApiService from "@/core/services/api.service";

// action type
export const FETCH_WHITELIST = "fetchWhitelist";

// mutation types
export const SET_WHITELIST = "setWhitelist";

const state = {
  whitelist: [],
};

const actions = {
  [FETCH_WHITELIST](context) {
    return new Promise((resolve) => {
      ApiService.setHeader();
      ApiService.get("v1/me/whitelists")
        .then(({ data }) => {
          context.commit(SET_WHITELIST, data.data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {
  [SET_WHITELIST](state, whitelists) {
    state.whitelist = whitelists;
  },
};

export default {
  state,
  actions,
  mutations,
};
